import { useState, useEffect, useContext, useCallback } from "react";
import { Button, Subtitle1, Caption1 } from "@fluentui/react-components";
import { TagDismissRegular, BranchRequestRegular } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";
import { generateRandomId } from "../../../lib/common";
import Announcement from "../../../components/Announcement";
import { MessageGroup } from "../../../components/MessageGroup";
import { TagsDataGrid } from "./TagsDataGrid";
import { TagDetailsDialog } from "./TagDetailsDialog";
import { TagDeleteDialog } from "./TagDeleteDialog";
import { TagMergeDialog } from "./TagMergeDialog";

export function Tags() {
  const { organizationApiUrl, usingMobileDevice } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [tags, setTags] = useState([]);
  const [toDisplayTags, setToDisplayTags] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const [isNewTagDialogOpen, setIsNewTagDialogOpen] = useState(false);
  const [selectedTagToEdit, setSelectedTagToEdit] = useState();
  const [selectedTagToDelete, setSelectedTagToDelete] = useState();

  const [gridKey, setGridKey] = useState(0);
  const [isMerging, setIsMerging] = useState(false);
  const [selectedTagIdsToMerge, setSelectedTagIdsToMerge] = useState([]);
  const [displayMergeDialog, setDisplayMergeDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTags();
  }, []);

  useEffect(() => {
    applySearch();
  }, [searchText, tags]);

  const getTags = async () => {
    try {
      const { data } = await axiosInstance.get("/org/tags");
      setTags(data);
      setAvailableCategories(
        [...new Set(data.map((t) => t.category))].filter((cat) => cat !== null)
      );
    } catch (e) {
      setMessages((prevMessages) => [
        {
          id: generateRandomId(8),
          intent: "error",
          title: "Oops!",
          body: `Some unexpected error has occurred and Tags could not be obtained. ${e.message}. Please, try again later`,
          dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
        },
        ...prevMessages,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const applySearch = useCallback(() => {
    let result = tags;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.value.toLowerCase().includes(searchText.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.category?.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setToDisplayTags(result);
  }, [tags, searchText]);

  return (
    <div className="app-body inline-content">
      <div style={{ marginTop: "1em" }}>
        <MessageGroup messages={messages} />
      </div>

      {loading ? null : (
        <>
          <TagDetailsDialog
            isOpen={isNewTagDialogOpen}
            onClose={(refresh) => {
              setIsNewTagDialogOpen(false);
              if (refresh) getTags();
            }}
            mode="create"
            availableCategories={availableCategories}
          />
          {tags && tags.length === 0 ? (
            <>
              <div style={{ marginTop: "5%" }} />
              <Announcement
                imgSrc="tags.png"
                imgAlt="No tags were found on your organization"
                imgWidth="20em"
                title="No tags were found on your organization"
                subtitle="Tags allow to classify incidents and group them for better search and statistics"
                actionComponent={
                  <Button appearance="primary" onClick={() => setIsNewTagDialogOpen(true)}>
                    Create tag
                  </Button>
                }
              />
            </>
          ) : (
            <div>
              <div className="flex-row" style={{ padding: "10px 0px" }}>
                <div style={{ display: "grid", gap: "5px" }}>
                  <Subtitle1>Tags</Subtitle1>
                  <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
                    Classify incidents and group them for better search and statistics
                  </Caption1>
                </div>
              </div>
              <div className="root">
                <div className="assigned-content" style={{ marginBottom: "4em" }}>
                  <TagDetailsDialog
                    isOpen={Boolean(selectedTagToEdit)}
                    onClose={(refresh) => {
                      setSelectedTagToEdit();
                      if (refresh) getTags();
                    }}
                    mode="edit"
                    selectedTag={selectedTagToEdit}
                    availableCategories={availableCategories}
                  />
                  <TagDeleteDialog
                    isOpen={Boolean(selectedTagToDelete)}
                    onClose={(refresh) => {
                      setSelectedTagToDelete();
                      if (refresh) getTags();
                    }}
                    toDeleteTag={selectedTagToDelete}
                  />
                  <TagMergeDialog
                    isOpen={displayMergeDialog}
                    onClose={(refresh) => {
                      setDisplayMergeDialog(false);
                      setIsMerging(false);
                      setSelectedTagIdsToMerge([]);
                      setGridKey((prev) => prev + 1);
                      if (refresh) getTags();
                    }}
                    toMergeTags={tags.filter((tag) => selectedTagIdsToMerge.includes(tag.id))}
                  />
                  <TagsDataGrid
                    gridKey={gridKey}
                    list={toDisplayTags}
                    compactView={usingMobileDevice}
                    onEditTag={setSelectedTagToEdit}
                    onDeleteTag={setSelectedTagToDelete}
                    onSearch={(text) => setSearchText(text)}
                    isInMergeMode={isMerging}
                    onSelectionChange={(e, data) => {
                      if (data !== null) {
                        setSelectedTagIdsToMerge([...data.selectedItems]);
                      }
                    }}
                    actionComponent={
                      <div>
                        {!isMerging && (
                          <div className="flex-btns">
                            <Button
                              icon={<TagDismissRegular style={{ transform: "rotate(44deg)" }} />}
                              appearance="primary"
                              onClick={() => setIsNewTagDialogOpen(true)}
                            >
                              Add new Tag
                            </Button>
                            <Button
                              icon={
                                <BranchRequestRegular style={{ transform: "rotate(180deg)" }} />
                              }
                              appearance="subtle"
                              onClick={() => setIsMerging(true)}
                            >
                              Merge Tags
                            </Button>
                          </div>
                        )}
                        {isMerging && (
                          <div className="flex-btns">
                            <Button appearance="secondary" onClick={() => setIsMerging(false)}>
                              Cancel
                            </Button>
                            <Button
                              icon={
                                <BranchRequestRegular style={{ transform: "rotate(180deg)" }} />
                              }
                              appearance="primary"
                              disabled={selectedTagIdsToMerge.length <= 1}
                              onClick={() => setDisplayMergeDialog(true)}
                            >
                              Merge Tags
                              {selectedTagIdsToMerge.length > 0 &&
                                ` (${selectedTagIdsToMerge.length})`}
                            </Button>
                          </div>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
